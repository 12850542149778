@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.accordion {
  padding: 0 !important;
  border: none !important;
  margin-bottom: 0 !important;

  &.full {
    flex: 1;
    width: 100%;
  }

  &.hidden {
    display: none;
  }

  .content {
    margin: 0 !important;
    padding: 6px 0 0 !important;

    &.dropdown {
      overflow: visible;
    }

    &.padded {
      padding: 10px 0 0 !important;
    }
  }

  &.noMargin {
    margin-bottom: 0 !important;
  }
}

.authContainer {
  background-color: $clr-white;
  border-radius: 16px;
  margin: 25px 0;
  max-width: 480px;
  width: calc(100% - 32px);

  &.wide {
    max-width: 515px !important;
  }

  &.full {
    max-height: 700px !important;
    max-width: 850px !important;
    width: 100%;
    height: fit-content;
  }

  &.fullScreen {
    max-height: 100% !important;
    width: 100%;
    height: fit-content;

    @media (max-width: $gl-breakpoint-webflow-phone-w) {
      height: 100%;
      border-radius: 0;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    overflow: auto;
    max-height: calc(100% - 600px);
    position: relative;

    &.full {
      max-height: 460px;
    }

    &.overflow {
      overflow: visible;
    }

    .description {
      @include font-size($gl-font-size-base);
      color: $clr-black-medium;
      margin: 0;
    }

    .fullWidth {
      max-width: none;
    }
  }

  .footer {
    border-top: 1px solid #d1d4e4;
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: flex-end;
    padding: 24px;
    width: 100%;

    .button {
      padding: 0 30px;
    }

    @media (max-width: $gl-breakpoint-webflow-phone) {
      &.allButtons {
        flex-direction: column;
      }
    }
  }

  .footerText {
    @include font-size($gl-font-size-base);
    color: $clr-black-medium;
    margin: 0 24px 24px;

    .button {
      color: $clr-primary;
      font-weight: $gl-font-weight-medium;
      cursor: pointer;
    }
  }

  .footerMessage {
    @include font-size($gl-font-size-base);
    color: $clr-black-medium;
    margin: 0;

    .button {
      color: $clr-primary;
      font-weight: $gl-font-weight-medium;
      cursor: pointer;
    }

    .button:hover {
      color: $clr-green;
    }

    &.checkBox {
      display: flex;
      align-items: flex-start;
      gap: 8px;
    }
  }

  .header {
    background-color: #faf9fa;
    border-radius: 16px 16px 0 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-height: 84px;
    padding: 16px 16px 16px 24px;

    .titleContainer {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      margin-top: 11px;

      .close {
        padding: 0;
        height: 30px;
        width: 30px;
        margin: 0 11px;
      }

      .title {
        @include font-size($gl-font-size-xl);
        color: $clr-black-high;
        font-weight: $gl-font-weight-medium;
        line-height: 36px;
        margin: 0;
        width: 100%;
      }
    }
    .subtitle {
      @include font-size($gl-font-size-base);
      color: $clr-black-medium;
      margin: 0;
    }
  }
}

.forgotPassword {
  @include font-size($gl-font-size-base);
  color: $clr-primary;
  cursor: pointer;
  font-weight: $gl-font-weight-medium;
}

.forgotPassword:hover {
  color: $clr-green;
}

.passwordContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.main {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #e7e7e7;
}

.modal {
  align-items: center;
  backdrop-filter: blur(7.5px);
  background-color: rgba(30, 73, 110, 0.3);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $gl-z-index-modal;
}

.signout {
  display: flex;
  flex-direction: column;

  .organizationTitle {
    margin: 0 0 20px;
  }

  .organizations {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .organizationItem {
    background-color: #f2f2f7;
    border-radius: 8px;
    padding: 10px 8px 10px 12px;
    justify-content: space-between;

    &.addOrganization {
      justify-content: flex-start;
      gap: 10px;
      color: $clr-black-medium;
    }
  }
}

.inviteMemberApps {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;
}

.inviteMemberAppItem {
  align-items: center;
  background-color: #f6f6f6;
  border-radius: 8px;
  display: flex;
  height: 72px;
  padding-left: 16px;
  justify-content: space-between;

  @media (max-width: $gl-breakpoint-webflow-phone) {
    flex-direction: column;
    height: auto;
    padding: 16px;
  }

  .inviteMemberContent {
    display: flex;
    align-items: center;

    @media (max-width: $gl-breakpoint-webflow-phone) {
      justify-content: space-between;
      width: 100%;

      &.top {
        padding-bottom: 16px;
      }

      &.bottom {
        padding-top: 16px;
        border-top: 1px solid $clr-gray-divider;
      }
    }
  }

  .inviteMemberAppIcon {
    border-radius: 8px;
    background-color: $clr-white;
    height: 40px;
    width: 40px;
    margin-right: 16px;
  }

  .inviteMemberAppName {
    flex: 1;
    margin-right: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .inviteMemberAccess {
    @media (max-width: $gl-breakpoint-webflow-phone) {
      padding: 13px 16px 13px 0;
    }

    &:hover {
      background-color: transparent !important;
    }

    .inviteMemberAccessButton {
      align-items: center;
      display: flex;
      justify-content: center;
      gap: 10px;
      text-transform: capitalize;
    }
  }

  .inviteMemberRemove {
    padding: 16px;
    margin-left: 4px;
  }
}

.inputLabel {
  margin: 0 !important;
}

.organizationDropDown {
  margin-top: 5px;
}

.nameAndEmailContainer {
  display: flex;
  gap: 16px;
  align-items: center;

  @media screen and (max-width: $gl-breakpoint-webflow-phone-w) {
    flex-direction: column;
  }
}

.formLabel {
  max-width: none;
}

.emailContainer {
  @include font-size($gl-font-size-base);
  display: flex;
  gap: 6px;
  color: $clr-black-medium;
}

.emailContent {
  width: 100%;
  color: $clr-black-medium;
}

.appIconContainer {
  display: flex;
  height: 42px;
  width: 42px;
  border-radius: 8px;
  object-fit: contain;
  box-shadow: 2px 2px 16px 0px rgba(30, 73, 110, 0.07);
  background-color: $clr-white-high;

  @include mobile {
    border-radius: 4px;
    height: 32px;
    width: 32px;
  }
}

.titleAndAppIconContainer {
  display: flex;
  gap: 16px;
  align-items: center;
}

.appNameContainer {
  display: flex;
  gap: 16px;
  align-items: center;
  @include font-size($gl-font-size-l);
  font-weight: $gl-font-weight-medium;
  padding: 16px;
  border-radius: 8px;
  background-color: $clr-background-light;
}

.titleAndIcon {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hiddenEmailField {
  display: none;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;

  &.minHeight {
    min-height: 60px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.contentFull {
  @media (max-width: $gl-breakpoint-webflow-phone-w) {
    flex: 1;
    max-height: none !important;
  }
}

.agreeButton {
  gap: 8px;
  align-items: flex-start;
}

.buttonText {
  display: inline-block;
  text-align: left;
}

.link {
  text-decoration: underline;
  font-weight: $gl-font-weight-medium;
  cursor: pointer;

  &:hover {
    color: $clr-green;
  }
}

.customTitleContainer {
  display: flex;
  flex-direction: column;
}
